import axios from 'axios'
import {createRefresh} from 'react-auth-kit'

const refreshApi = createRefresh({
    interval: 1,   // Refreshs the token in every 10 minutes
    refreshApiCallback: async (
        {   // arguments
            authToken,
            authTokenExpireAt,
            refreshToken,
            refreshTokenExpiresAt,
            authUserState
        }) => {
        try {
            const response = await axios.post("/auth/refresh", {'token': refreshToken})

            console.log(">>> refreshApiCallback")
            console.log("authToken", refreshToken)
            console.log("refreshToken", refreshToken)
            console.log("response.data.token", response.data.token)
            axios.defaults.headers.common['token'] = 'Bearer '+response.data.token;
            return {
                isSuccess: true,
                newAuthToken: response.data.token,
                // newAuthTokenExpireIn: 1*24*60,
                newAuthTokenExpireIn: 60,
                // newRefreshToken: response.data.token,
                // newRefreshTokenExpiresIn: 60
                // newRefreshTokenExpiresIn: 1*24*60
                // newRefreshTokenExpiresIn: 60
            }
        }
        catch(error){
            console.error(error)
            return {
                isSuccess: false
            }
        }
    }
})

export default refreshApi
