import { useEffect, useState } from "react"

const decode = (value) => {
    return JSON.stringify(value)
}

const encode = (value) => {
    return JSON.parse(value)
}

// useLocalStorage hooks
const useLocalStorage = (key, defaultState) => {
    const [value, setValue] = useState(
        encode(localStorage.getItem(key)||null) || defaultState
    )

    useEffect(() => {
        localStorage.setItem(key, decode(value))
    },  [key, value])

    return [value, setValue]
}

export {
    useLocalStorage
}
