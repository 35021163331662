import React, {useState} from 'react';
import './App.css';
import {SignIn} from "./SignIn/SignIn";
import "./Calendar/Calendar.css";
import {Route, Routes} from "react-router-dom";
// import {RequireAuth, useIsAuthenticated} from "react-auth-kit";
import {SignUp} from "./SignUp/SignUp";
// import {Main} from "./Main/Main";
// import AuthOutlet from '@auth-kit/react-router/AuthOutlet'
import {RequireAuth} from "react-auth-kit";
import {Finance} from "./Finance/Finance";
import {Insights} from "./Insights/Insights";
import {Perfomance} from "./Perfomance/Perfomance";
import {Reviews} from "./Reviews/Reviews";
import {Offline} from "react-detect-offline";
import {Alert, Container} from "react-bootstrap";
import {Menu} from "./Menu/Menu";
import {Calendar} from "./Calendar/Calendar";
// import {Main} from "./Main/Main";

function App() {

    // const isAuthenticated = useIsAuthenticated()
    const [isOpen, setOpen] = useState(false)

    const handleIsOpen = () => {
        setOpen(!isOpen)
    }

    const closeSideBar = () => {
        setOpen(false)
    }

    return (
        <>
            <Offline>
                <Alert variant="danger" >
                    <b>You are offline!</b> Please check your Internet Connection...
                </Alert>
            </Offline>
            <Routes>

                <Route path="/login" element={<SignIn/>}></Route>
                <Route path="/register" element={<SignUp/>}></Route>
                <Route path="/" element={<RequireAuth loginPath="/login">
                    <Container>
                        <Menu isOpen={isOpen} handleIsOpen={handleIsOpen} closeSideBar={closeSideBar}/>
                        <Calendar/>
                    </Container>
                </RequireAuth>}></Route>
                <Route path="/finance" element={<RequireAuth loginPath="/login">
                    <Container>
                        <Menu isOpen={isOpen} handleIsOpen={handleIsOpen} closeSideBar={closeSideBar}/>
                        <Finance/>
                    </Container>
                </RequireAuth>}></Route>
                <Route path="/insights" element={<RequireAuth loginPath="/login">
                    <Container>
                        <Menu isOpen={isOpen} handleIsOpen={handleIsOpen} closeSideBar={closeSideBar}/>
                        <Insights/>
                    </Container>
                </RequireAuth>}></Route>
                <Route path="/perfomance" element={<RequireAuth loginPath="/login">
                    <Container>
                        <Menu isOpen={isOpen} handleIsOpen={handleIsOpen} closeSideBar={closeSideBar}/>
                        <Perfomance/>
                    </Container>
                </RequireAuth>}></Route>
                <Route path="/reviews" element={<RequireAuth loginPath="/login">
                    <Container>
                        <Menu isOpen={isOpen} handleIsOpen={handleIsOpen} closeSideBar={closeSideBar}/>
                        <Reviews/>
                    </Container>
                </RequireAuth>}></Route>

                {/*<Route path="/" element={<RequireAuth loginPath="/login">*/}
                {/*    <Main/>*/}
                {/*</RequireAuth>}></Route>*/}
                {/*<Route path="/finance" element={<RequireAuth loginPath="/login"><Finance/></RequireAuth>}></Route>*/}
                {/*<Route path="/perfomance" element={<RequireAuth loginPath="/login"><Perfomance/></RequireAuth>}></Route>*/}
                {/*<Route path="/reviews" element={<RequireAuth loginPath="/login"><Reviews/></RequireAuth>}></Route>*/}
                {/*<Route element={<AuthOutlet fallbackPath='/login' />}>*/}
                {/*    <Route path="/" element={<Main/>} />*/}
                {/*    <Route path="/main" element={<Main/>} />*/}
                {/*</Route>*/}
            </Routes>

        </>
    );
}

export default App;
