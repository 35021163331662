import React, {useEffect} from 'react';
import './Insights.css'
import './Table/Table.css'
import {Stack} from "react-bootstrap";
import logo from "../image/logo.webp";
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select, { SelectChangeEvent } from '@mui/material/Select';
import GuidesTable from "./Table/Table";
import ReviewsTable from "./Table/ReviewsTable";
import {useAuthHeader, useAuthUser} from "react-auth-kit";
import axios from "axios";
// import Moment from "moment";
// import Chart from './Chart';
import './Chart.css';
import {useLocalStorage} from "../hooks/localstorage";

export function Insights() {

    const [guide, setGuide] = useLocalStorage('guide', '');
    // const [guide, setGuide] = React.useState('');
    // const [guideList, setGuideList] = React.useState([]);
    const [guideInsights, setGuideInsights] = React.useState([]);
    const [reviewsInsights, setReviewsInsights] = React.useState([]);

    const auth = useAuthUser(null)
    const authHeader = useAuthHeader()

    useEffect(() => {
        if (auth()?.role === 'guide') {
            setGuide(auth()?.display_name);
            return
        }

        axios.defaults.headers.common['token'] = authHeader()
        axios.get("/api/guides")
            .then((response) => {
                if (response.data && response.data.length > 0) {
                    // setGuideList(response.data)
                }
                return response.data;
            })
        // eslint-disable-next-line
    }, []);

    // const [rows, setRows] = React.useState([]);

    // const authHeader = useAuthHeader()
    useEffect(() => {
        axios.defaults.headers.common['token'] = authHeader()
        axios.get("/api/insights")
            .then((response) => {
                if (response.data && response.data.length >= 0) {
                    setGuideInsights(response.data)
                }
                return response.data;
            })
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        axios.defaults.headers.common['token'] = authHeader()
        axios.get("/api/reviews_insights")
            .then((response) => {
                if (response.data && response.data.length >= 0) {
                    setReviewsInsights(response.data)
                }
                return response.data;
            })
        // eslint-disable-next-line
    }, []);
    //
    // const handleChange = (event: SelectChangeEvent) => {
    //     setGuide(event.target.value);
    // };

    console.log(reviewsInsights)

    // const paidRows = rows.filter(row => !(row.net_amount + row.net_2nd_amount + row.invoice_amount > 0 && (Moment(row.net_payment_date).year() >= 2024 || Moment(row.invoice_payment_date).year() >= 2024 || Moment(row.net_2nd_payment_date).year() >= 2024)))
    // const upcomingRows = rows.filter(row => row.net_amount + row.net_2nd_amount +  + row.invoice_amount > 0 && (Moment(row.net_payment_date).year() >= 2024 || Moment(row.invoice_payment_date).year() >= 2024 || Moment(row.net_2nd_payment_date).year() >= 2024))

    return (
        <div>
            <Stack direction="horizontal" style={{padding: '10px 0px'}} gap={1}>
                <div className="p-1 col-1">
                    <img src={logo} className="logo" alt="Emotion.club" width={50}/>
                </div>
                <div className="p-1 col-11">
                    <h2 className="text-center">Insights</h2>
                </div>
            </Stack>
            <div className="finance" style={{paddingBottom: '100px', marginTop: '50px'}}>
                <ReviewsTable key={2} rows={reviewsInsights}></ReviewsTable>
                <GuidesTable key={1} guide={guide} rows={guideInsights}></GuidesTable>
            </div>
        </div>
    )
}
