import React from 'react';
// import Box from '@mui/material/Box';
// import Collapse from '@mui/material/Collapse';
// import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import './Table.css'
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import './Table.js'


function RowReviews(props: { row: any, isPaid: boolean, guide: any}, isUpcoming2: boolean) {
    const {row} = props;
    const [open, setOpen] = React.useState(false);

    function twoDecimals(n) {
        var log10 = n ? Math.floor(Math.log10(n)) : 0,
            div = log10 < 0 ? Math.pow(10, 1 - log10) : 100;

        return Math.round(n * div) / div;
    }
    function rating(row) {

        let starsTotal = 0
        starsTotal += row.before_five_stars_count * 5
        starsTotal += row.before_four_stars_count * 4
        starsTotal += row.before_three_stars_count * 3
        starsTotal += row.before_two_stars_count * 2
        starsTotal += row.before_one_stars_count

        let starsCount = 0
        starsCount += row.before_five_stars_count
        starsCount += row.before_four_stars_count
        starsCount += row.before_three_stars_count
        starsCount += row.before_two_stars_count
        starsCount += row.before_one_stars_count

        return starsTotal && twoDecimals(starsTotal/starsCount)
    }
    return (
        <React.Fragment>
            <TableRow
                sx={{'& > *': {borderBottom: 'unset'}}}
                onClick={() => setOpen(!open)}
                style={{cursor: "pointer"}}
            >
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                    >
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.date}
                </TableCell>
                <TableCell align="center">{row.five_stars_count}</TableCell>
                <TableCell align="center">{row.four_stars_count}</TableCell>
                <TableCell align="center">{row.three_stars_count}</TableCell>
                <TableCell align="center">{row.two_stars_count}</TableCell>
                <TableCell align="center">{row.one_stars_count}</TableCell>
                <TableCell align="center">{row.reviews_count}</TableCell>
                <TableCell align="center">{row.stars_count && twoDecimals(row.stars_total/row.stars_count)}</TableCell>
                <TableCell align="center">€{row.refund}</TableCell>
                {/*<TableCell align="center">432</TableCell>*/}
            </TableRow>

            <TableRow>
                <TableCell className="collapse22" style={{paddingBottom: 0, paddingTop: 0, padding: 0}} colSpan={10}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{margin: 1}}>
                            <Table size="small" aria-label="variant1">
                                <TableHead>
                                    <TableRow>
                                        {/*<TableCell/>*/}
                                        <TableCell align="center"></TableCell>
                                        <TableCell align="center">Before</TableCell>
                                        <TableCell align="center">After</TableCell>
                                        <TableCell align="center">Refund</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            5*
                                        </TableCell>
                                        <TableCell align="center">{row.before_five_stars_count}</TableCell>
                                        <TableCell align="center">{row.five_stars_count}</TableCell>
                                        <TableCell align="center">€{row.refund_five_stars}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            4*
                                        </TableCell>
                                        <TableCell align="center">{row.before_four_stars_count}</TableCell>
                                        <TableCell align="center">{row.four_stars_count}</TableCell>
                                        <TableCell align="center">€{row.refund_four_stars}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            3*
                                        </TableCell>
                                        <TableCell align="center">{row.before_three_stars_count}</TableCell>
                                        <TableCell align="center">{row.three_stars_count}</TableCell>
                                        <TableCell align="center">€{row.refund_three_stars}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            2*
                                        </TableCell>
                                        <TableCell align="center">{row.before_two_stars_count}</TableCell>
                                        <TableCell align="center">{row.two_stars_count}</TableCell>
                                        <TableCell align="center">€{row.refund_two_stars}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            1*
                                        </TableCell>
                                        <TableCell align="center">{row.before_one_stars_count}</TableCell>
                                        <TableCell align="center">{row.one_stars_count}</TableCell>
                                        <TableCell align="center">€{row.refund_one_stars}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            Rating
                                        </TableCell>
                                        <TableCell align="center">{rating(row)}</TableCell>
                                        <TableCell align="center">{row.stars_count && twoDecimals(row.stars_total/row.stars_count)}</TableCell>
                                        <TableCell align="center">€{row.refund_one_stars+row.refund_two_stars+row.refund_three_stars+row.refund_four_stars+row.refund_five_stars}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>

                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function ReviewsTable({rows}) {

    return (
        <>
            <div style={{margin: "50px 0px 20px 0px"}}>
                <h5>Reviews insights</h5>
            </div>
            {rows.length > 0 ?
                <TableContainer component={Paper} className="review_table">
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                {/*<TableCell/>*/}
                                <TableCell align="center"></TableCell>
                                <TableCell align="center">Month</TableCell>
                                <TableCell align="center">5*</TableCell>
                                <TableCell align="center">4*</TableCell>
                                <TableCell align="center">3*</TableCell>
                                <TableCell align="center">2*</TableCell>
                                <TableCell align="center">1*</TableCell>
                                <TableCell align="center">Total</TableCell>
                                <TableCell align="center">Rating</TableCell>
                                <TableCell align="center">Refund</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <RowReviews key={row.date} row={row}/>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                :
                <div>list empty</div>
            }
        </>
    );
}
