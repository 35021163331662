import React from "react"
import axios from 'axios'
import { useSignIn } from 'react-auth-kit'
import { useNavigate } from "react-router-dom";
import { Col, Button, Row, Container, Card, Form, FloatingLabel } from "react-bootstrap";
import { jwtDecode } from 'jwt-decode'
import logo from './../image/logo.webp'

export const SignIn = () => {
    const signIn = useSignIn()
    const [formData, setFormData] = React.useState({email: '', password: '', terms: 'checked'})
    const [ errors, setErrors ] = React.useState({})
    // const setField = (field, value) => {
    //     setFormData({
    //         ...formData,
    //         [field]: value
    //     })
    //     // Check and see if errors exist, and remove them from the error object:
    //     // if ( !!errors[field] ) setErrors({
    //     //     ...errors,
    //     //     [field]: null
    //     // })
    // }
    const findFormErrors = () => {
        const { email, password, terms } = formData
        const newErrors = {}
        if ( !email || email === '' ) newErrors.email = 'cannot be blank!'
        else if ( email.length > 50 ) newErrors.email = 'email is too long!'
        if ( !password || password === '' ) newErrors.password = 'cannot be blank!'
        if ( terms === undefined || !terms ) newErrors.terms = 'You must agree before submitting.'
        return newErrors
    }

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const navigate = useNavigate();
    const onSubmit = (e) => {
        e.preventDefault()

        const newErrors = findFormErrors()
        if ( Object.keys(newErrors).length > 0 ) {
            // We got errors!
            setErrors(newErrors)
        } else {
            axios.post('/auth/login', formData)
                .then((res)=>{

                    if(res.status !== 200){
                        console.log('res.status === 200', res.status, res.data, res.data.message)
                        return
                    }
                    if (res.data.token === undefined) {
                        console.log('res.data.token === undefined', res.data)
                        return
                    }

                    const user = jwtDecode(res.data.token); // decode your token here
                    console.log('user', user)
                    console.log('res', res)
                    signIn(
                        {
                            token: res.data.token,
                            expiresIn:'10',
                            tokenType: "Bearer",
                            authState: user,
                            // authState: {},
                            refreshToken: res.data.token,                    // Only if you are using refreshToken feature
                            refreshTokenExpireIn: "10"                   // Only if you are using refreshToken feature
                            // refreshToken: res.data.token,                    // Only if you are using refreshToken feature
                            // refreshTokenExpireIn: '10'    // Only if you are using refreshToken feature
                        })
                    axios.defaults.headers.common['token'] = "Bearer "+res.data.token;
                    navigate("/");
                    return res.data
                })
                .catch((e) => {
                    console.log('catch signIn', e)

                    if (e.response === undefined) {
                        setErrors({password: e.message})
                    } else if( e.response.data.status !== 200){
                        console.log('res.status === 200', e.response.data.status, e.response.data, e.response.data.message)
                        setErrors({password: e.response.data.message})
                        return
                    }
                    // navigate("/");
                })
        }

    }

    return (
        <Container>
            <Row className="vh-100 d-flex justify-content-center align-items-center">
                <Col md={8} lg={6} xs={12}>
                    {/*<div className="border border-3 border-success"></div>*/}
                    <Card className="shadow">
                        <Card.Body>
                            <div className="mb-3 mt-md-4">

                                <Col xs={12} align="center" >
                                    <img src={logo} className="logo" alt="logo" width={100}/>
                                </Col>
                                {/*<h2 className="fw-bold mb-2 text-uppercase ">Emotion.club</h2>*/}
                                {/*<p className=" mb-5">Please enter your login and password!</p>*/}
                                <div className="mb-3 mt-3">
                                    <Form onSubmit={onSubmit} validated={false} noValidate>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="Email address"
                                                className="mb-3"
                                            >
                                                <Form.Control isInvalid={ !!errors.email } required type="email" placeholder="name@example.com" onChange={(e) => setFormData({...formData, email: e.target.value})}/>
                                                <Form.Control.Feedback type="invalid">
                                                    { errors.email }
                                                </Form.Control.Feedback>
                                            </FloatingLabel>
                                            {/*<Form.Label className="text-center">*/}
                                            {/*    Email address*/}
                                            {/*</Form.Label>*/}
                                            {/*<Form.Control type="text" placeholder="Enter email" onChange={(e) => setFormData({...formData, email: e.target.value})}/>*/}
                                        </Form.Group>

                                        <Form.Group
                                            className="mb-3"
                                            controlId="formBasicPassword"
                                        >
                                            <FloatingLabel
                                                controlId="formBasicPassword1"
                                                label="Password"
                                                className="mb-3"
                                            >
                                                <Form.Control isInvalid={ !!errors.password } required type="password" placeholder="Password" onChange={(e) => setFormData({...formData, password: e.target.value})}/>
                                                <Form.Control.Feedback type="invalid">
                                                    { errors.password }
                                                </Form.Control.Feedback>
                                            </FloatingLabel>
                                            {/*<Form.Label>Password</Form.Label>*/}
                                            {/*<Form.Control type="password" placeholder="Password"  onChange={(e) => setFormData({...formData, password: e.target.value})}/>*/}
                                        </Form.Group>
                                        <Form.Group
                                            className="mb-3"
                                            controlId="formBasicCheckbox"
                                        >
                                            {/*<p className="small">
                                                <a className="text-primary" href="#!">
                                                    Forgot password?
                                                </a>
                                            </p>*/}
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Check
                                                required
                                                onChange={(e) => setFormData({...formData, terms: e.target.checked ? "checked" : ""})}
                                                name="terms"
                                                value={formData.term}
                                                defaultChecked={true}
                                                label="Agree to terms and conditions"
                                                isInvalid={!!errors.terms}
                                                feedback={errors.terms}
                                                feedbackType="invalid"
                                                id="validationFormik0"
                                            />
                                        </Form.Group>
                                        <div className="d-grid">
                                            <Button variant="success" type="submit">
                                                Login
                                            </Button>
                                        </div>
                                    </Form>
                                    {/*<div className="mt-3">
                                        <p className="mb-0  text-center">
                                            Don't have an account?{" "}
                                            <a href="" onClick={() => navigate("/register")} className="text-primary fw-bold">
                                                Sign Up
                                            </a>
                                        </p>
                                    </div>*/}
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>


    )
}
