import React from 'react';
import { slide as Sidebar } from 'react-burger-menu'
import './Menu.css'
import {useIsAuthenticated, useSignOut, useAuthUser} from "react-auth-kit";
import {NavLink} from "react-router-dom";
// import {Insights} from "../Insights/Finance";
// import Button from "react-bootstrap/Button";

export function Menu({isOpen, handleIsOpen, closeSideBar}) {
    const signOut = useSignOut()
    const isAuthenticated = useIsAuthenticated()
    // const navigate = useNavigate();

    function handleClick(e) {
        // navigate("/login");
        signOut()
        closeSideBar()
    }

    const auth = useAuthUser(null)

    if (!isAuthenticated()) {
        return (<></>)
    }
    return (
        <Sidebar right outerContainerId={"root"}
                 isOpen={isOpen}
                 onOpen={handleIsOpen}
                 onClose={handleIsOpen}>
            <NavLink to="/" onClick={closeSideBar}>Calendar</NavLink>
            <NavLink to="/finance" onClick={closeSideBar}>Finance</NavLink>
            <NavLink to="/reviews" onClick={closeSideBar}>Reviews</NavLink>
            {auth()?.role === 'admin' && <NavLink to="/insights" onClick={closeSideBar}>Insights</NavLink> }
            <NavLink to="/login"  onClick={() => handleClick()} style={{position: "absolute", bottom: "10px"}}>Sign Out</NavLink>
        </Sidebar>
    )
}
