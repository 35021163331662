import React from 'react';
// import './Perfomance.css'
import {Stack} from "react-bootstrap";
import logo from "../image/logo.webp";
import { Rating } from "flowbite-react";
// import type { CustomFlowbiteTheme } from "flowbite-react";
import { Flowbite } from "flowbite-react";

const customTheme = {
    "base": "flex items-center",
    "label": "text-sm font-medium text-cyan-600 dark:text-cyan-500",
    "progress": {
        "base": "mx-4 h-5 w-2/4 rounded bg-gray-200 dark:bg-gray-700",
        "fill": "h-5 rounded bg-yellow-400",
        "label": "text-sm font-medium text-cyan-600 dark:text-cyan-500"
    },

    "root": {
        "base": "flex items-center"
    },
    "star": {
        "empty": "text-gray-300 dark:text-gray-500",
        "filled": "text-yellow-400",
        "sizes": {
            "sm": "h-5 w-5",
            "md": "h-7 w-7",
            "lg": "h-10 w-10"
        }
    }
};

export function Perfomance() {
    return (
        <div>
            <Stack direction="horizontal" style={{padding: '10px 0px'}} gap={1}>
                <div className="p-1 col-1">
                    <img src={logo} className="logo" alt="Emotion.club" width={50}/>
                </div>
                <div className="p-1 col-11">
                    <h2 className="text-center">Perfomance</h2>
                </div>
            </Stack>
            <div>
                <Flowbite theme={{ theme: customTheme }}>
                    <Rating className="mb-2">
                        <Rating.Star/>
                        <Rating.Star/>
                        <Rating.Star/>
                        <Rating.Star/>
                        <Rating.Star filled={false}/>
                        <p className="ml-2 text-sm font-medium text-gray-500 dark:text-gray-400">4.95 out of 5</p>
                    </Rating>
                    <p className="mb-4 text-sm font-medium text-gray-500 dark:text-gray-400">1,745 global ratings</p>
                    <Rating.Advanced percentFilled={70} className="mb-2">
                        5 star
                    </Rating.Advanced>
                    <Rating.Advanced percentFilled={17} className="mb-2">
                        4 star
                    </Rating.Advanced>
                    <Rating.Advanced percentFilled={8} className="mb-2">
                        3 star
                    </Rating.Advanced>
                    <Rating.Advanced percentFilled={4} className="mb-2">
                        2 star
                    </Rating.Advanced>
                    <Rating.Advanced percentFilled={1}>1 star</Rating.Advanced>
                </Flowbite>
            </div>
        </div>

    )
}
