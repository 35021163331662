import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from 'react-auth-kit'
import { BrowserRouter } from "react-router-dom";
import refreshApi from "./refreshApi";
import axios from 'axios';
// import * as serviceWorker from './serviceWorker';

if (process.env.NODE_ENV === 'development') {
    axios.defaults.baseURL = 'http://localhost:7787';
} else {
    axios.defaults.baseURL = 'https://team.emotion.club';
}
axios.defaults.crossDomain = true
axios.defaults.withCredentials = false
axios.defaults.headers.post['Content-Type'] = 'application/json';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <AuthProvider authType='localstorage'
                    authName='_auth'
                    refresh={refreshApi}
                    cookieDomain={window.location.hostname}
                    cookieSecure={window.location.protocol === "https:"}>
          <BrowserRouter>
              <App />
          </BrowserRouter>
      </AuthProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register({
//     onSuccess: () => store.dispatch({ type: SW_INIT }),
//     onUpdate: registration =>
//         store.dispatch({ type: SW_UPDATE, payload: registration }),
// });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
